<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">保护展示与环境整治工程记录信息</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <div class="form_basic">
                <!-- <mapDraw
                    :basicMap="taskMap"
                    :drawConf="drawConf"
                    :jsonStr="jsonStr"
                    @update:jsonStr="getJsonStr"
                >
                </mapDraw> -->
                <Mymap
                    ref="myMap"
                    class="map"
                    :BoundsList="bounds"
                    :mapData="mapData"
                    @initmap="initMap"
                >
                    <!-- <div class="map-tool">
                        <sn-button :snButton="newButton" @handleChange="handleChange"></sn-button>
                    </div> -->
                </Mymap>
                <el-form
                    ref="formdata"
                    :inline="false"
                    :model="formdata.dataDic"
                    class="demo-form-block"
                    size="small"
                    label-width="200px"
                >
                    <sn-button
                        v-if="isCheck"
                        class="checkBtn"
                        :snButton="checkButton"
                        @handleChange="handleChange"
                    ></sn-button>
                    <el-form-item label="工程名称" prop="GCMC">
                        <el-input
                            v-model="formdata.dataDic.GCMC"
                            :disabled="true"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="涉及到的遗产构成要素"
                        prop="SJDDYCGCYS"
                    >
                        <el-select
                            v-model="YSBMList"
                            filterable
                            multiple
                            :disabled="true"
                            @change="changeYSBM"
                        >
                            <el-option
                                v-for="v in ycysList"
                                :key="v.Bm"
                                :label="v.Mc"
                                :value="v.Bm"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="工程类型" prop="GCFL">
                        <el-select
                            v-model="formdata.dataDic.GCFL"
                            :disabled="true"
                        >
                            <el-option
                                v-for="(item, i) in gcflList"
                                :key="i"
                                :label="item"
                                :value="i + ''"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <!-- 工程类型为保护工程时选择工程性质 -->
                    <!-- <el-form-item v-if="formdata.dataDic.GCFL === '0'" label="工程性质" prop="GCXZ">
                        <el-select v-model="formdata.dataDic.GCXZ" filterable :disabled="true">
                            <el-option v-for="(item, i) in gcxzList" :key="i" :value="i + ''" :label="item"></el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item label="文保单位名称">
                        <!-- <el-select v-model="formdata.dataDic.WBDWMC" filterable multiple  :disabled="true" @change="changeYSBM">
                            <el-option v-for="v in ycysList" :key="v.BM" :label="v.MC" :value="v.BM">
                            </el-option>
                        </el-select> -->
                        <el-input
                            v-model="formdata.dataDic.WBDWMC"
                            onkeydown="if(event.keyCode==32) return false"
                            :disabled="true"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item label="施工单位">
                        <el-input
                            v-model="formdata.dataDic.SGDW"
                            onkeydown="if(event.keyCode==32) return false"
                            :disabled="true"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item label="监理单位" prop="JLDW">
                        <el-input
                            v-model="formdata.dataDic.JLDW"
                            onkeydown="if(event.keyCode==32) return false"
                            :disabled="true"
                        >
                        </el-input>
                    </el-form-item>
                    <!-- 任何时间可编辑 -->
                    <el-form-item label="投入经费总额" prop="GJTRZJF">
                        <el-input
                            v-model="formdata.dataDic.GJTRZJF"
                            :disabled="!specialIsEdit"
                            onkeydown="if(event.keyCode==32) return false"
                        >
                            <template slot="append">万元</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="保护工程经费-国家补助">
                        <el-input
                            v-model="formdata.dataDic.BHGCGJBZJF"
                            onkeydown="if(event.keyCode==32) return false"
                            :disabled="true"
                        >
                            <template slot="append">万元</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="保护工程经费-地方配套">
                        <el-input
                            v-model="formdata.dataDic.BHGCDFPTJF"
                            onkeydown="if(event.keyCode==32) return false"
                            :disabled="true"
                        >
                            <template slot="append">万元</template>
                        </el-input>
                    </el-form-item>
                    <!-- 任何时间可编辑 -->
                    <el-form-item label="开工时间">
                        <el-date-picker
                            v-model="formdata.dataDic.KGSJ"
                            :picker-options="pickerKGSJOptions"
                            value-format="yyyy-MM-dd"
                            :disabled="!specialIsEdit"
                            onkeydown="if(event.keyCode==32) return false"
                            type="date"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <!-- 任何时间可编辑 -->
                    <el-form-item label="竣工时间" prop="JGSJ">
                        <el-date-picker
                            v-model="formdata.dataDic.JGSJ"
                            :picker-options="pickerJGSJOptions"
                            value-format="yyyy-MM-dd"
                            :disabled="!specialIsEdit"
                            onkeydown="if(event.keyCode==32) return false"
                            type="date"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="竣工验收日期">
                        <el-date-picker
                            v-model="formdata.dataDic.JGYSRQ"
                            :picker-options="pickerJGYSRQOptions"
                            value-format="yyyy-MM-dd"
                            onkeydown="if(event.keyCode==32) return false"
                            :disabled="true"
                            type="date"
                        >
                        </el-date-picker>
                    </el-form-item>
                </el-form>
            </div>
            <div class="botton_box">
                <span class="menu_title">文物保护维修档案</span>
            </div>
            <div class="form_info">
                <el-tabs class="step-tabs" type="card">
                    <el-tab-pane>
                        <span slot="label" class="tab-name"
                            >立项/方案<i :class="setIconClass('lxfa')"></i
                        ></span>
                        <div class="tab-main">
                            <el-form
                                ref="formdata1"
                                :inline="true"
                                :model="formdata.dataDic"
                                size="small"
                                class="demo-form-inline form_row"
                                label-width="200px"
                            >
                                <p class="model-title">立项基本信息</p>
                                <el-form-item label="立项名称">
                                    <el-input
                                        v-model="formdata.dataDic.FAMC"
                                        onkeydown="if(event.keyCode==32) return false"
                                        :disabled="true"
                                    >
                                    </el-input> </el-form-item
                                ><el-form-item label="批准复文号">
                                    <el-input
                                        v-model="formdata.dataDic.PZFWH"
                                        onkeydown="if(event.keyCode==32) return false"
                                        :disabled="true"
                                    >
                                    </el-input>
                                </el-form-item>
                                <el-form-item
                                    label="立项开始年月"
                                    prop="LXKSNY"
                                >
                                    <el-date-picker
                                        v-model="formdata.dataDic.LXKSNY"
                                        format="yyyy-MM"
                                        value-format="yyyy-MM"
                                        type="month"
                                        onkeydown="if(event.keyCode==32) return false"
                                        :disabled="true"
                                    >
                                    </el-date-picker>
                                </el-form-item>

                                <p class="model-title">方案基本信息</p>
                                <el-form-item label="设计单位" prop="SJDW">
                                    <el-input
                                        v-model="formdata.dataDic.SJDW"
                                        onkeydown="if(event.keyCode==32) return false"
                                        :disabled="true"
                                    >
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="方案开始年月">
                                    <el-date-picker
                                        v-model="formdata.dataDic.FAKSNY"
                                        format="yyyy-MM"
                                        value-format="yyyy-MM"
                                        type="month"
                                        onkeydown="if(event.keyCode==32) return false"
                                        :disabled="true"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item
                                    label="方案设计阶段"
                                    prop="FASJJD"
                                >
                                    <el-input
                                        v-model="formdata.dataDic.FASJJD"
                                        onkeydown="if(event.keyCode==32) return false"
                                        :disabled="true"
                                    >
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="方案编制单位">
                                    <el-input
                                        v-model="formdata.dataDic.FABZDW"
                                        onkeydown="if(event.keyCode==32) return false"
                                        :disabled="true"
                                    >
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="方案批准复文号">
                                    <el-input
                                        v-model="formdata.dataDic.FAPFWH"
                                        onkeydown="if(event.keyCode==32) return false"
                                        :disabled="true"
                                    >
                                    </el-input>
                                </el-form-item>
                                <el-form-item
                                    label="方案批复年份"
                                    prop="FAPFNF"
                                >
                                    <el-date-picker
                                        v-model="formdata.dataDic.FAPFNF"
                                        type="year"
                                        format="yyyy"
                                        value-format="yyyy"
                                        onkeydown="if(event.keyCode==32) return false"
                                        :disabled="true"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                                <!-- 任何时间可编辑 -->
                                <el-form-item label="方案设计经费-国家补助">
                                    <el-input
                                        v-model="formdata.dataDic.FASJGJBZJF"
                                        :disabled="!specialIsEdit"
                                        onkeydown="if(eventkey.Code==32) return false"
                                    >
                                        <template slot="append">万元</template>
                                    </el-input>
                                </el-form-item>
                                <!-- 任何时间可编辑 -->
                                <el-form-item label="方案设计经费-地方配套">
                                    <el-input
                                        v-model="formdata.dataDic.FASJDFPTJF"
                                        :disabled="!specialIsEdit"
                                        onkeydown="if(event.keyCode==32) return false"
                                    >
                                        <template slot="append">万元</template>
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="是否上报世界遗产中心">
                                    <el-select
                                        v-model="formdata.dataDic.SFSBSJYCZX"
                                        filterable
                                        :disabled="true"
                                    >
                                        <el-option
                                            value="1"
                                            label="是"
                                        ></el-option>
                                        <el-option
                                            value="0"
                                            label="否"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>

                                <p class="model-title">立项/方案 附件</p>
                                <div class="file-main">
                                    <div v-for="(item, i) in LXFALIST" :key="i">
                                        <p
                                            v-if="i === LXFALIST.length - 1"
                                            class="file-title"
                                        >
                                            是否有其他类型的附件
                                            <el-radio-group
                                                v-model="
                                                    formdata.dataDic.LXFAQTFILE
                                                "
                                                :disabled="true"
                                            >
                                                <el-radio :label="1"
                                                    >是</el-radio
                                                >
                                                <el-radio :label="0"
                                                    >否</el-radio
                                                >
                                            </el-radio-group>
                                        </p>
                                        <div
                                            v-if="
                                                i !== LXFALIST.length - 1 ||
                                                formdata.dataDic.LXFAQTFILE
                                            "
                                        >
                                            <p class="file-title">
                                                {{ item.fjlb
                                                }}<i
                                                    :class="
                                                        checkFileState(
                                                            item.metaJson
                                                        )
                                                    "
                                                ></i>
                                            </p>
                                            <basic-upload
                                                ref="uploadFile"
                                                :isCheckShow="isCheck"
                                                :code="item.code"
                                                :isEdit="!true"
                                                :itemId="itemId"
                                                :metaData.sync="item.metaJson"
                                                basicType="document"
                                                @handleChange="handleChange"
                                            ></basic-upload>
                                        </div>
                                    </div>
                                </div>
                            </el-form>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane>
                        <span slot="label" class="tab-name"
                            >过程档案<i :class="setIconClass('gcda')"></i
                        ></span>
                        <div class="tab-main">
                            <el-form
                                ref="formdata2"
                                :inline="true"
                                :model="formdata.dataDic"
                                size="small"
                                class="demo-form-inline form_row"
                                label-width="200px"
                            >
                                <div class="file-main">
                                    <div v-for="(item, i) in GCDALIST" :key="i">
                                        <p
                                            v-if="i === GCDALIST.length - 1"
                                            class="file-title"
                                        >
                                            是否有其他类型的附件
                                            <el-radio-group
                                                v-model="
                                                    formdata.dataDic.GCDAQTFILE
                                                "
                                                :disabled="true"
                                            >
                                                <el-radio :label="1"
                                                    >是</el-radio
                                                >
                                                <el-radio :label="0"
                                                    >否</el-radio
                                                >
                                            </el-radio-group>
                                        </p>
                                        <div
                                            v-if="
                                                i !== GCDALIST.length - 1 ||
                                                formdata.dataDic.GCDAQTFILE
                                            "
                                        >
                                            <p class="file-title">
                                                {{ item.fjlb
                                                }}<i
                                                    :class="
                                                        checkFileState(
                                                            item.metaJson
                                                        )
                                                    "
                                                ></i>
                                            </p>
                                            <basic-upload
                                                ref="uploadFile"
                                                :isCheckShow="isCheck"
                                                :code="item.code"
                                                :isEdit="!true"
                                                :itemId="itemId"
                                                :metaData.sync="item.metaJson"
                                                basicType="document"
                                                @handleChange="handleChange"
                                            ></basic-upload>
                                        </div>
                                    </div>
                                </div>
                            </el-form>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane>
                        <span slot="label" class="tab-name"
                            >竣工验收<i :class="setIconClass('jgys')"></i
                        ></span>
                        <div class="tab-main">
                            <el-form
                                ref="formdata3"
                                :inline="true"
                                :model="formdata.dataDic"
                                size="small"
                                class="demo-form-inline form_row"
                                label-width="200px"
                            >
                                <el-form-item label="竣工验收日期">
                                    <el-date-picker
                                        v-model="formdata.dataDic.JGYSRQ"
                                        value-format="yyyy-MM-dd"
                                        onkeydown="if(event.keyCode==32) return false"
                                        :disabled="true"
                                        type="date"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item
                                    label="竣工验收文号"
                                    prop="JGYSWH"
                                >
                                    <el-input
                                        v-model="formdata.dataDic.JGYSWH"
                                        onkeydown="if(event.keyCode==32) return false"
                                        :disabled="true"
                                    >
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="国家文物局验收日期">
                                    <el-date-picker
                                        v-model="formdata.dataDic.GJWWJYSRQ"
                                        value-format="yyyy-MM-dd"
                                        onkeydown="if(event.keyCode==32) return false"
                                        :disabled="true"
                                        type="date"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item label="工程验收结论">
                                    <el-input
                                        v-model="formdata.dataDic.JGYSJL"
                                        onkeydown="if(event.keyCode==32) return false"
                                        :disabled="true"
                                    >
                                    </el-input>
                                </el-form-item>
                                <div class="file-main">
                                    <div v-for="(item, i) in JGYSLIST" :key="i">
                                        <p
                                            v-if="i === JGYSLIST.length - 1"
                                            class="file-title"
                                        >
                                            是否有其他类型的附件
                                            <el-radio-group
                                                v-model="
                                                    formdata.dataDic.JGYSQTFILE
                                                "
                                                :disabled="true"
                                            >
                                                <el-radio :label="1"
                                                    >是</el-radio
                                                >
                                                <el-radio :label="0"
                                                    >否</el-radio
                                                >
                                            </el-radio-group>
                                        </p>
                                        <div
                                            v-if="
                                                i !== JGYSLIST.length - 1 ||
                                                formdata.dataDic.JGYSQTFILE
                                            "
                                        >
                                            <p class="file-title">
                                                {{ item.fjlb
                                                }}<i
                                                    :class="
                                                        checkFileState(
                                                            item.metaJson
                                                        )
                                                    "
                                                ></i>
                                            </p>
                                            <basic-upload
                                                ref="uploadFile"
                                                :isCheckShow="isCheck"
                                                :code="item.code"
                                                :isEdit="!true"
                                                :itemId="itemId"
                                                :metaData.sync="item.metaJson"
                                                basicType="document"
                                                @handleChange="handleChange"
                                            ></basic-upload>
                                        </div>
                                    </div>
                                </div>
                            </el-form>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import infoMixin from "../0_com_js/info_mixin.js";
import { mapActions } from "vuex";
import mapDraw from "@comp/map/mapDraw/MapDraw";
export default {
    name: "bhhjzsyhjzzggjl",
    components: {
        mapDraw,
    },
    mixins: [infoMixin],
    data() {
        return {
            mapData: {
                dxtState: true,
                areaState: true,
                baseMapNum: 0,
                mapTypeBtn: true,
            },
            isEdit: true,
            checkButton: {
                buttonData: [
                    {
                        isShow: true,
                        btnType: "button",
                        operateType: "saveBasePass",
                        editType: "2",
                        name: "审核通过",
                        round: true,
                        backColor: "#28cdd9",
                        color: "#fff",
                    },
                    {
                        isShow: true,
                        btnType: "button",
                        operateType: "saveBaseNoPass",
                        editType: "2",
                        name: "审核不通过",
                        round: true,
                        backColor: "#6f32ff",
                        color: "#fff",
                    },
                ],
            },
            taskMap: null,
            bounds: [
                [42.4082, 116.1114],
                [42.3353, 116.2267],
            ],
            saveButton: true,
            formdata: {
                itemCode: "1501",
                dataDic: {
                    GCMC: "",
                    SJDDYCGCYS: "",
                    GCFL: "",
                    GCXZ: "",
                    WBDWMC: "",
                    SGDW: "",
                    JLDW: "",
                    GJTRZJF: "",
                    BHGCGJBZJF: "",
                    BHGCDFPTJF: "",
                    KGSJ: "",
                    JGSJ: "",
                    JGYSRQ: "",
                    FAMC: "",
                    PZFWH: "",
                    LXKSNY: "",
                    SJDW: "",
                    FAKSNY: "",
                    FASJJD: "",
                    FABZDW: "",
                    FAPFWH: "",
                    FAPFNF: null,
                    FASJGJBZJF: "",
                    FASJDFPTJF: "",
                    SFSBSJYCZX: "",
                    JGYSWH: "",
                    GJWWJYSRQ: "",
                    JGYSJL: "",
                    GCXMZB: "", // 工程范围
                    LXFAQTFILE: 0, // 立项/方案是否有其他类型的附件
                    GCDAQTFILE: 0, // 过程档案是否有其他类型的附件
                    JGYSQTFILE: 0, // 竣工验收是否有其他类型的附件
                    GLYCBTID: "11012", // 写死就好
                    SHZT: 1,
                },
            },
            specialIsEdit: false, // 控制特殊编辑项--有编辑权限的随时可编辑
            isCheck: false, // 是否可审核
            YSBMList: [], // 涉及的遗产要素（多选）
            ycysList: [], // 涉及的遗产要素列表
            gcflList: [
                "本体保护工程",
                "展示工程",
                "保护性设施建设工程（安消防除外）",
                "环境整治工程",
                "监测工程",
                "其他",
            ],
            gcxzList: [
                "保养维护工程",
                "抢险加固工程",
                "修缮工程",
                "保护性设施建设工程",
                "迁移工程",
            ],
            // 开工时间
            pickerKGSJOptions: {
                disabledDate: (time) => {
                    return (
                        time.getTime() >
                            new Date(this.formdata.dataDic.JGSJ).getTime() ||
                        time.getTime() >
                            new Date(this.formdata.dataDic.JGYSRQ).getTime()
                    );
                },
            },
            // 竣工时间
            pickerJGSJOptions: {
                disabledDate: (time) => {
                    return (
                        time.getTime() <
                            new Date(this.formdata.dataDic.KGSJ).getTime() ||
                        time.getTime() >
                            new Date(this.formdata.dataDic.JGYSRQ).getTime()
                    );
                },
            },
            // 竣工验收时间
            pickerJGYSRQOptions: {
                disabledDate: (time) => {
                    return (
                        time.getTime() <
                            new Date(this.formdata.dataDic.KGSJ).getTime() ||
                        time.getTime() <
                            new Date(this.formdata.dataDic.JGSJ).getTime()
                    );
                },
            },
            // planB
            newButton: {
                buttonData: [
                    {
                        btnType: "button",
                        operateType: "creatArea",
                        name: "绘制工程范围",
                        round: true,
                        backColor: "#28ccd9",
                        color: "#fff",
                    },
                ],
            },
            // 立项方案附件
            LXFALIST: [
                { code: "8", metaJson: [], fjlb: "立项批复文件" },
                { code: "9", metaJson: [], fjlb: "立项报告" },
                { code: "10", metaJson: [], fjlb: "方案" },
                { code: "11", metaJson: [], fjlb: "方案批复文件" },
                { code: "12", metaJson: [], fjlb: "不同意及其他文件" },
                { code: "1", metaJson: [], fjlb: "其他" },
            ],
            // 过程档案附件
            GCDALIST: [
                { code: "13", metaJson: [], fjlb: "监理报告" },
                { code: "0", metaJson: [], fjlb: "其他" },
            ],
            // 竣工验收附件
            JGYSLIST: [
                { code: "14", metaJson: [], fjlb: "竣工报告" },
                { code: "16", metaJson: [], fjlb: "专家意见" },
                { code: "2", metaJson: [], fjlb: "其他" },
            ],
            lxfaQt: 0,
            gcdaQt: 0,
            jgysQt: 0,
            jsonStr: null,
            drawConf: {
                position: "topleft",
                show: true,
                draw: {
                    polygon: true,
                    polyline: false,
                    rectangle: true,
                },
            },
        };
    },
    computed: {},
    mounted() {
        this.getYcys();
    },
    methods: {
        ...mapActions(["getYcysList"]),
        initMap(map) {
            this.taskMap = map;
        },
        async getYcys() {
            let res = await this.getYcysList({
                ycdbm: this.heritageId,
            });
            this.ycysList = res.ResultValue;
        },
        changeYSBM() {
            this.formdata.dataDic.SJDDYCGCYS = this.YSBMList.join(",");
        },
        setMetaJsonData() {
            let fileList = ["LXFALIST", "GCDALIST", "JGYSLIST"];
            this.formdata.metaJson = [];
            fileList.forEach((item) => {
                this[item].forEach((ele) => {
                    ele.metaJson.forEach((item) => {
                        item.SHZT = item.SHZT === 3 ? 0 : item.SHZT;
                    });
                    this.formdata.metaJson = this.formdata.metaJson.concat(
                        ele.metaJson
                    );
                });
            });
        },
        getMetaJsonData() {
            let allFile = this.formdata.metaJson;
            let fileList = ["LXFALIST", "GCDALIST", "JGYSLIST"];
            fileList.forEach((item) => {
                this[item].forEach((ele) => {
                    ele.metaJson = allFile.filter((i) => {
                        return i.lx === ele.code;
                    });
                });
            });
            this.LXFAQTFILE = this.formdata.dataDic.LXFAQTFILE;
            this.GCDAQTFILE = this.formdata.dataDic.GCDAQTFILE;
            this.JGYSQTFILE = this.formdata.dataDic.JGYSQTFILE;
        },
        GetDataNext() {
            this.getMetaJsonData();
        },
        // 新建巡查范围
        creatArea() {
            // let option = {
            //     allowIntersection: false,
            //     showArea: true
            // };
            // this.isFirstinPolygon = false;
            // // eslint-disable-next-line
            // this.myPolygon = new L.Draw.Polygon(this.taskMap, option);
            // this.myPolygon.enable();
        },
        // 标绘操作
        getJsonStr(JsonStr, event) {
            let jsonStr = JSON.parse(JsonStr);
            if (this.operateMarker) {
                this.taskMap.removeLayer(this.operateMarker);
            }
            if (jsonStr.length === 0) return false;
            let json = jsonStr[jsonStr.length - 1].geometry;
            if (json.type !== "Polygon") return false;
            this.pointJson = jsonStr;
            let typeList = ["draw:created", "draw:editstop", "draw:deleted"]; // 创建、编辑、删除
            if (typeList.indexOf(event.type) > -1) {
                this.formdata.dataDic.GCXMZB = JsonStr;
            }
        },
        getArea() {
            let areaJson = this.formdata.dataDic.GCXMZB;
            if (!areaJson) return false;
            this.jsonStr = areaJson; // 后续在mapDraw中渲染区域
        },
        // 检查每一个文件后面的图标状态
        checkFileState(data) {
            let className = "";
            let hasNoPass = false;
            if (this.isEdit) {
                hasNoPass = data.some((item) => {
                    return item.SHZT === 3;
                });
                className =
                    data.length === 0 || hasNoPass
                        ? "el-icon-warning"
                        : "el-icon-success";
            } else if (this.isCheck) {
                hasNoPass = data.some((item) => {
                    return !item.SHZT;
                });
                className = hasNoPass ? "el-icon-warning" : "el-icon-success";
            }
            return className;
        },
        // 图标控制
        setIconClass(name) {
            let res = this.checkAllFileState(this[name + "List"]);
            return res;
        },
        // 检查文件的状态
        checkAllFileState(data) {
            // 编辑：未上传、审核未通过 -- warning
            // 审核：未审核 -- warning
            // 查看：不显示状态
            if (this.btnType === "0") {
                // 查看
                return "";
            } else if (this.btnType === "1") {
                // 编辑
                let res = data.every((item) => {
                    let res1 = item.metaJson.every((ele) => {
                        return ele.SHZT === "2";
                    });
                    return res1 && item.metaJson.length > 0;
                });
                return res ? "el-icon-success" : "el-icon-warning";
            } else if (this.btnType === "2") {
                // 审核
                let res = data.every((item) => {
                    let res1 = item.metaJson.every((ele) => {
                        return ele.SHZT;
                    });
                    return res1;
                });
                return res ? "el-icon-success" : "el-icon-warning";
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.form_basic {
    padding: 10px 0;
    position: relative;
    overflow: hidden;
    .map {
        width: 60%;
        position: relative;
        height: 600px;
        float: left;
        .map-tool {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 999;
        }
    }
    .el-form {
        width: 40%;
        float: left;
        .checkBtn {
            width: 100%;
            text-align: right;
        }
    }
}
.form_info {
    i {
        margin-left: 10px;
        &.el-icon-success {
            color: #67c23a;
        }
        &.el-icon-warning {
            color: #e6a23c;
        }
    }
    padding: 10px 0;
    .tab-name {
        font-size: 16px;
    }
    .model-title {
        font-size: 14px;
        font-weight: 700;
        height: 24px;
        line-height: 24px;
        border-bottom: 1px solid #ccc;
        margin-bottom: 10px;
    }
    .file-main {
        .file-title {
            padding-left: 20px;
        }
    }
}
</style>
